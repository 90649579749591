import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles/theme/colors';

export interface WorkDTO {
  id: string;
  name: string;
  stars: number;
  type: string;
  description: string;
  url: string;
}

export interface WorkProps {
  work: WorkDTO;
}

const WorkCardLink = styled.a`
  text-decoration: none;
`;

const WorkCard = styled.div`
  background-color: ${Colors.darkGray};
  width: 100% !important;
  padding: 2rem;
  margin-bottom: 1rem;
  color: ${Colors.white};
  
  &:hover {
    color: ${Colors.gray};
    cursor: pointer;
  } 
`;

const Work: FC<WorkProps> = (props) => {
  const { work } = props;
  return (
    <WorkCardLink href={work.url}
       target='_blank'
       rel="noopener noreferrer">
      <WorkCard>
        {work.type} <br/>
        <br/>
        {work.name} <br/>
        {work.description} <br/>
        {work.stars}
      </WorkCard>
    </WorkCardLink>
  );
};

export default Work;
