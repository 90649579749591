export type breakpointsType = {
  s: number;
  m: number;
  l: number;
};

export const Breakpoints: breakpointsType = {
  s: 568,
  m: 768,
  l: 1024
};

export const useBreakpoint = (breakpoint: keyof breakpointsType) => `@media (min-width: ${Breakpoints[breakpoint]}px)`;
