import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles/theme/colors';

export interface HeadlineProps {
  children?: JSX.Element[] | JSX.Element | string;
}

const StyledHeadline = styled.h2`
  color: ${Colors.white};
  margin-bottom: 2rem;
`;

const Headline: FC<HeadlineProps> = (props) => {
  const { children } = props;
  return (<StyledHeadline>{children}</StyledHeadline>);
};

export default Headline;
