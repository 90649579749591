import React, { FC } from 'react';
import ProjectsList from './ProjectsList';
import Headline from '../layout/Headline';

const Projects: FC = () => {
  return (
    <div className='projects'>
      <Headline>
        Projects
      </Headline>
      <ProjectsList/>
    </div>
  );
};

export default Projects;
