import React, { FC } from 'react';
import WorkList from './WorkList';
import Headline from '../layout/Headline';

const Works: FC = () => {
  return (
    <div className='works'>
      <Headline>
        Works
      </Headline>
      <WorkList/>
    </div>
  );
};

export default Works;
