import React, { FC } from 'react';
import Work, { WorkDTO } from './Work';

const worksData: WorkDTO[] = [
  {
    id: 'dummy',
    name: 'dummy',
    description: 'dummy',
    type: 'dummy',
    stars: 9999,
    url: 'https://github.com',
  },
  {
    id: 'dummy',
    name: 'dummy',
    description: 'dummy',
    type: 'dummy',
    stars: 9999,
    url: 'https://github.com',
  },
  {
    id: 'dummy',
    name: 'dummy',
    description: 'dummy',
    type: 'dummy',
    stars: 9999,
    url: 'https://github.com',
  },
  {
    id: 'dummy',
    name: 'dummy',
    description: 'dummy',
    type: 'dummy',
    stars: 9999,
    url: 'https://github.com',
  },
  {
    id: 'dummy',
    name: 'dummy',
    description: 'dummy',
    type: 'dummy',
    stars: 9999,
    url: 'https://github.com',
  },
];

const WorkList: FC = () => {
  return (
    <div>
      {
        worksData.map((work: WorkDTO, index: number) => {
          return <Work work={work} key={`work-key-${index}`}/>;
        })
      }
    </div>
  );
};

export default WorkList;
