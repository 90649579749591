import React, { FC, useEffect, useState } from 'react';
import Project, { ProjectDTO } from './Project';
import axios from 'axios';

// let projectsData: ProjectDTO[] | null = null;

const filterProjects = (projects: any[]): ProjectDTO[] => {
  return projects.map((project: any) => {
    return {
      id: project.id,
      name: project.name,
      stars: project.stargazers_count,
      type: project.language,
      description: project.description,
      url: project.html_url,
    };
  });
};

const fetchProjects = (setProjectData: (projects: ProjectDTO[] | null) => void) => {
  const url: string = 'https://api.github.com/users/cr0n3x/repos';

  axios.get(url)
    .then((response) => {
      setProjectData(filterProjects(response.data));
    });
};

const ProjectsList: FC = () => {

  const [projectsData, setProjectData] = useState<ProjectDTO[] | null>(null);

  useEffect(() => {
      fetchProjects(setProjectData);
    },
    []);

  return (
    <div>
      {
        projectsData && projectsData.map((project: ProjectDTO, index: number) => {
          return <Project project={project} key={`project-key-${index}`}/>;
        })
      }
    </div>
  );
};

export default ProjectsList;
