import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/theme/colors';
import { useBreakpoint } from '../styles/theme/breakpoints';
import Projects from './projects/Projects';
import Works from './works/Works';


const ApplicationContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 1.5rem 3rem;
  flex-direction: column;
  ${useBreakpoint('m')}{
    padding: 3rem 0 0 6rem;
    flex-direction: row;
  }
  ${useBreakpoint('l')}{
    padding: 6rem 1rem 0 6rem;
  }
`;

const StaticContentContainer = styled.div`
   position: sticky;
   ${useBreakpoint('m')} {
    max-width: 50%;
   }
   ${useBreakpoint('l')} {
    }
`;

const ScrollableContentContainer = styled.div`
   ${useBreakpoint('m')}{
    overflow: auto;
    flex: 1;
  }
`;

const MainHeadline = styled.h1`
  color: ${Colors.white};
  font-size: 2rem;
  margin-bottom: 1rem;
   ${useBreakpoint('l')}{
    font-size: 4rem;
  }
`;

const Description = styled.p`
  color: ${Colors.gray};
  font-size: .8rem;
   ${useBreakpoint('l')}{
    font-size: 1rem;
    max-width: 70%;
  }
`;

const TextLink = styled.a`
  color: ${Colors.white};
  text-decoration: underline;
  font-size: .8rem;
  padding: 0 0 0 5px;
   ${useBreakpoint('l')}{
    font-size: 1rem;
  }
`;

const LinkList = styled.div`

`;


const Application: FC = () => {
  return (
    <ApplicationContainer>
      <StaticContentContainer>
        <MainHeadline>
          Hello I`m <br/>
          Christian Müller.
        </MainHeadline>
        <Description>
          I’m a software engineer currently working as senior web developer in an ux team at
          <TextLink target='_blank' href='https://arithnea.de/'>ARITHNEA GmbH</TextLink>.
          I mostly do front-end development, and I’m a
          <TextLink href='https://vuejs.org/' target='_blank'>Vue.js</TextLink> and
          <TextLink target='_blank' href='https://typescript.org/'>Typescript</TextLink> nerd.
        </Description>
        <LinkList>

        </LinkList>
      </StaticContentContainer>
      <ScrollableContentContainer>
        <Projects/>
        <Works/>
      </ScrollableContentContainer>
    </ApplicationContainer>
  );
};

export default Application;
