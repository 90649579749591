import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles/theme/colors';

export interface ProjectDTO {
  id: string;
  name: string;
  stars: number;
  type: string;
  description: string;
  url: string;
}

export interface ProjectProps {
  project: ProjectDTO;
}

const ProjectCardLink = styled.a`
  text-decoration: none;
`;

const ProjectCard = styled.div`
  background-color: ${Colors.darkGray};
  width: 100% !important;
  padding: 2rem;
  margin-bottom: 1rem;
  color: ${Colors.white};
  
  &:hover {
    color: ${Colors.gray};
    cursor: pointer;
  } 
`;

const Project: FC<ProjectProps> = (props) => {
  const { project } = props;
  return (
    <ProjectCardLink href={project.url}
       target='_blank'
       rel="noopener noreferrer">
      <ProjectCard>
        {project.type}
        <br/>
        <br/>
        {project.name}
        <br/>
        {project.description}
        <br/>
        {project.stars}
      </ProjectCard>
    </ProjectCardLink>
  );
};

export default Project;
